import React, {useEffect} from 'react';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';

export default function NoticeModal(props) {
  const {visible, onClose} = props;
  const [locale] = useOutlet('locale');
  const [agree, setAgree] = React.useState(false);

  useEffect(() => {
    if (!visible) {
      setAgree(false);
    }
  }, [visible]);

  return (
    <Ant.Modal
      title={AppActions.L('booking_notice')}
      visible={visible}
      maskClosable={false}
      closable={false}
      footer={
        <div style={{display: 'flex'}}>
          <Ant.Checkbox
            checked={agree}
            onChange={(e) => setAgree(e.target.checked)}>
            {AppActions.L('agree_booking_notice')}
          </Ant.Checkbox>
          <div style={{flex: 1}} />
          <Ant.Button
            type="primary"
            onClick={() => {
              if (!agree) {
                Ant.message.warning(
                  AppActions.L('agree_booking_notice_warning'),
                );
                return;
              } else {
                onClose();
              }
            }}>
            {AppActions.L('confirm')}
          </Ant.Button>
        </div>
      }
      width={900}>
      {locale.lang === 'en' ? (
        <div style={{whiteSpace: 'pre-wrap'}}>
          {`1. When proceed your online booking, please make sure you agree with the prepaid policy for your stay and the following principles to ensure your right to room booking and price.

2. To ensure your credit card transaction security, please provide your correspond identity when check-in.

3. Once the booking is confirmed, any cancellation activity please call 037-268818 direct line to contact for further services.

4. The price presented online has included tax and service fee.

5. Check-in time is 15:00 pm, check-out time is 10:00 am

6. Any contingency due to natural cause; Typhoon, mountain collapse, please contact the hotel 3 days in advance to get further assistance.

7. The cancellation penalty are as follow:
• Guest cancel the booking on the check-in day, which will be no refund
• Guest cancel the booking 1 day in advance, which will refund 20% of from the prepaid.
• Guest cancel the booking 2-3 day in advance, which will refund 30% of from the prepaid.
• Guest cancel the booking 4-6 day in advance, which will refund 40% of from the prepaid.
• Guest cancel the booking 7-9 day in advance, which will refund 50% of from the prepaid.
• Guest cancel the booking 10-13 day in advance, which will refund 70% of from the prepaid.
• Guest cancel the booking 14 day in advance, which will refund 100% of from the prepaid.
`}
        </div>
      ) : (
        <div style={{whiteSpace: 'pre-wrap'}}>
          {`※親愛的各位貴賓您好，觀霧山莊現場服務時間為－AM07：00至PM21：00，如有任何需求，請於上述服務時間親臨櫃台或是於電話服務時間－AM09：00至PM21：00致電037-268818．

※露營區僅限電話訂房，謝謝您※

※響應環保，不提供一次性備品(牙膏牙刷拖鞋等…)，請自行準備。

※房間開放往後三個月內，例如：1月1日開放到4月30日前，往後規則依此類推※

※＂若有特殊餐飲需求，如：素食、穆斯林、不吃牛羊豬、海鮮甲殼類過敏、或會對特定食材過敏等需求，請於訂房後來電或來訊告知山莊。＂※

※房費包含早晚兩餐，若訂房貴賓或續住房客需要加訂隔日午餐，請於辦理入住時與櫃檯同仁告知做登記付費之程序。

※ 山區日夜溫差大，請自行攜帶保暖衣物，因備品有限，如需加被每條酌收NT$ 200清潔費 (背包客房型不提供加被服務)

※官網訂單無法辨識國旅卡，欲使用國旅卡之遊客請務必來電洽詢預訂，謝謝您的配合。

1. 使用官網訂房系統訂房者，需同意支付訂單之訂金金額，及以下相關規定，以保留官網訂房之房間與價格之權利。

2. 下午六點後抵達客人，請改為電話或信箱訂房。
※不須享用晚餐方案的貴賓，請您來電訂房，請於20：00前抵達辦理入住。

3. 為保障您信用卡交易的安全，本網站規範線上訂房人應與辦理住宿（check in）者資料相符，並須於辦理住宿的同時提供相同身份證明文件。

4. 透過線上訂房確認後，若您需要取消訂房，請直接撥打訂房專線037-268818或發送電子郵件至guanwuvilla@guanwuvilla.com與服務人員聯繫。

5. 官網上之價格皆已含稅及服務費。

6. 山莊入住時間為下午15：00；退房時間為早上10：00。
如您為不須享用晚餐方案的貴賓，請您來電訂房，另提醒入住時間為20：00前抵達．以免打擾到其他房客安寧．
※若需於15:00前進房，請提早來電告知，並依您入房時間酌收NT$ 500元/小時
※若超過退房時間，延遲至11：00退房，將酌收延遲退房費NT$ 500元/小時
※若超過退房時間，延遲至12：00退房，將酌收延遲退房費NT$總房價之50%

7. 登記住宿日若遇山莊所在地發生不可抗拒之因素，如因土石坍塌整修導致交通中斷、或中央氣象局發佈陸上颱風警報，請於入住日起三日內(含當日)，與訂房專線聯絡。

8. 取消訂房之扣款比例如下：
• 旅客於住宿日當日取消訂房，即不退還已付全部訂金 。
• 旅客於住宿日1日前取消訂房，可退還已付訂金金額20% 。
• 旅客於住宿日2-3前日取消訂房，可退還已付訂金金額30% 。
• 旅客於住宿日4-6前日取消訂房，可退還已付訂金金額40% 。
• 旅客於住宿日7-9前日取消訂房，可退還已付訂金金額50% 。
• 旅客於住宿日10-13前日取消訂房，可退還已付訂金金額70% 。
• 旅客於住宿日14日(含)前取消訂房，可退還已付訂金金額100% 。

9. 以上除了取消訂房之扣款比例外，客人需自行負擔刷卡系統商的2.75%退刷手續費用（訂金費用的2.75%），再次提醒取消訂單須以致電方式或發送電子郵件至guanwuvilla@guanwuvilla.com告知山莊櫃檯辦理！

延期過住宿日的訂房規範：
※ 延期僅可延期一次； 延期一次後恕不接受再次延期及取消訂房之訂金退費
※ 延期訂金將為您保留3個月，如超過3個月將自動取消您的訂金及延期資格。
＜如有疑義，歡迎來電，將會有專員為您說明＞
`}
        </div>
      )}
    </Ant.Modal>
  );
}
